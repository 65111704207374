import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs';

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}
const defaultLayout = 'leen-tech'
const routes = [
    {
        path: "/",
        redirect: "/uved"
    },

    {
        path: '/typography',
        name: 'typography',
        meta: {layout: defaultLayout},
        component: loadView('Typography')
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Dashboard')
    },
    {
        path: '/dashboard/:uuid/show',
        name: 'dashboard.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DashboardShow')
    },

    {
        path: '/profile',
        name: 'profile',
        meta: {layout: defaultLayout, auth: true},
        component: loadView('AdministratorProfile')
    },
    {
        path: '/discuss_chat',
        name: 'discuss_chat',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director', 'manager', 'accountant','general_manager','guide','lawyer','hr_manager']}},
        component: loadView('DiscussChat')
    },
    {
        path: '/discuss_chat/:uuid',
        name: 'discuss_chat.open',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director', 'manager', 'accountant','general_manager','guide','lawyer','hr_manager']}},
        component: loadView('DiscussChat')
    },
    {
        path: '/calendar',
        name: 'calendar',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director', 'manager', 'accountant','general_manager','guide','lawyer','hr_manager']}},
        component: loadView('CalendarShow')
    },
    {
        path: '/calendar/kanban',
        name: 'calendar.kanban',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director', 'manager','accountant','general_manager','guide','hr_manager','lawyer']}},
        component: loadView('CalendarKanban')
    },
    {
        path: '/invoice_correction',
        name: 'invoice_correction',
        meta: {layout: defaultLayout, auth: {roles: ['administrator']}},
        component: loadView('InvoiceCorrection')
    },
    {
        path: '/topics_correction',
        name: 'topics_correction',
        meta: {layout: defaultLayout, auth: {roles: ['administrator']}},
        component: loadView('TopicsCorrection')
    },
    {
        path: '/tracking_correction',
        name: 'tracking_correction',
        meta: {layout: defaultLayout, auth: {roles: ['administrator']}},
        component: loadView('TrackingCorrection')
    },
    {
        path: '/administrator',
        name: 'administrator',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('Administrators')
    },
    {
        path: '/administrator/create',
        name: 'administrator.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('AdministratorProfile')
    },
    {
        path: '/administrator/:id/profile',
        name: 'administrator.profile',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('AdministratorProfile')
    },
    {
        path: '/work_day',
        name: 'work_day',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('WorkDays')
    },
    {
        path: '/vacation',
        name: 'vacation',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('Vacations')
    },
    {
        path: '/vacation/create',
        name: 'vacation.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('VacationForm')
    },
    {
        path: '/vacation/:id/edit',
        name: 'vacation.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('VacationForm')
    },
    {
        path: '/create_report',
        name: 'create_report',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('CreateReports')
    },
    {
        path: '/create_report/create',
        name: 'create_report.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('CreateReportForm')
    },
    {
        path: '/create_report/:id/edit',
        name: 'create_report.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('CreateReportForm')
    },
    {
        path: '/folder',
        name: 'folder',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('Folders')
    },
    {
        path: '/file_storage',
        name: 'file_storage',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director', 'manager','accountant','general_manager','guide','hr_manager','lawyer']}},
        component: loadView('FileStorages')
    },

    {
        path: '/employee_position',
        name: 'employee_position',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('EmployeePositions')
    },
    {
        path: '/employee_position/create',
        name: 'employee_position.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('EmployeePositionForm')
    },
    {
        path: '/employee_position/:id/edit',
        name: 'employee_position.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','hr_manager']}},
        component: loadView('EmployeePositionForm')
    },
    {
        path: '/client',
        name: 'client',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','manager','general_manager','guide']}},
        component: loadView('Clients')
    },
    {
        path: '/client/:id/profile',
        name: 'client.profile',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director','manager','general_manager','guide']}},
        component: loadView('ClientProfile')
    },
    {
        path: '/company',
        name: 'company',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Companies')
    },
    {
        path: '/company/create',
        name: 'company.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('CompanyForm')
    },
    {
        path: '/company/:id/edit',
        name: 'company.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('CompanyForm')
    },
    {
        path: '/company/:id/show',
        name: 'company.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('CompanyShow')
    },
    {
        path: '/tracking',
        name: 'tracking',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('Tracking')
    },
    {
        path: '/tracking_operation/:uuid/create',
        name: 'tracking_operation.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('TrackingOperationForm')
    },
    {
        path: '/tracking/create',
        name: 'tracking.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('TrackingForm')
    },
    {
        path: '/tracking/create/deal/:deal_uuid',
        name: 'tracking.create.deal',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TrackingForm')
    },
    {
        path: '/tracking/:uuid/edit',
        name: 'tracking.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TrackingForm')
    },
    {
        path: '/invoice',
        name: 'invoice',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Invoices')
    },
    {
        path: '/invoice/create',
        name: 'invoice.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('InvoiceForm')
    },
    {
        path: '/invoice/:id/edit',
        name: 'invoice.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('InvoiceForm')
    },
    {
        path: '/invoice/create/deal/:deal_uuid',
        name: 'invoice.create.deal',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('InvoiceForm')
    },
    {
        path: '/uved',
        name: 'uved',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Uveds'),
    },
    {
        path: '/uved/:id/show',
        name: 'uved.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('UvedShow'),
        props: true
    },
    {
        path: '/uved/create',
        name: 'uved.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('UvedForm')
    },
    {
        path: '/uved/:id/edit',
        name: 'uved.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('UvedForm')
    },
    {
        path: '/email_connect',
        name: 'email_connect',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('EmailConnects'),
    },
    {
        path: '/email_connect/create',
        name: 'email_connect.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('EmailConnectForm')
    },
    {
        path: '/telegram_connect',
        name: 'telegram_connect',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('TelegramConnects'),
    },
    {
        path: '/telegram_connect/create',
        name: 'telegram_connect.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('TelegramConnectForm')
    },
    {
        path: '/email_connect/:id/edit',
        name: 'email_connect.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('EmailConnectForm')
    },
    {
        path: '/uved_template/:type_client',
        name: 'uved_template',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('UvedTemplateForm')
    },
    {
        path: '/uved/permissions',
        name: 'uved_permissions',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']
            }
        },
        component: loadView('UvedPermissions')
    },
    {
        path: '/deal/',
        name: 'deal',
        redirect: '/deal/0'
    },
    {
        path: '/deal/:id',
        name: 'deal_id',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Deals')
    },
    {
        path: '/deal/create',
        name: 'deal.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DealForm')
    },
    {
        path: '/deal/task/:task_uuid/create',
        name: 'deal.create.task',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DealForm')
    },
    {
        path: '/deal/uved/:uved_uuid/create',
        name: 'deal.create.uved',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DealForm')
    },
    {
        path: '/deal/:id/edit',
        name: 'deal.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DealForm')
    },
    {
        path: '/deal/:id/show',
        name: 'deal.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DealShow'),
        props: true
    },
    {
        path: '/deal_kanban',
        name: 'deal.kanban',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DealKanban')
    },
    {
        path: '/deal_template/:business_process',
        name: 'deal_template',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealTemplateForm')
    },

    {
        path: '/task',
        name: 'task',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Tasks')
    },
    {
        path: '/task/create',
        name: 'task.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskForm')
    },
    {
        path: '/task/create/deal/:deal_uuid',
        name: 'task.create.deal',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskForm')
    },
    {
        path: '/task/create/uved/:uved_uuid',
        name: 'task.create.uved',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskForm')
    },
    {
        path: '/task/:id/edit',
        name: 'task.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskForm')
    },
    {
        path: '/task_template/:task_type',
        name: 'task_template',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskTemplateForm')
    },
    {
        path: '/task/:id/show',
        name: 'task.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskShow'),
        props: true
    },
    {
        path: '/subtask/:id/show',
        name: 'subtask.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskShow')
    },
    {
        path: '/subtask/:id/create',
        name: 'subtask.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('SubTaskForm')
    },

    {
        path: '/task/kanban',
        name: 'task.kanban',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TaskKanban')
    },

    {
        path: '/my_topic',
        name: 'my_topic',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('MyTopics')
    },
    {
        path: '/topics',
        name: 'topics',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Topics')
    },
    {
        path: '/topic/create',
        name: 'topic.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TopicForm')
    },
    {
        path: '/topic/:id',
        name: 'topic.message',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('TopicForm')
    },
    {
        path: '/invoice_status',
        name: 'invoice_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('InvoiceStatuses')
    },
    {
        path: '/invoice_status/create',
        name: 'invoice_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('InvoiceStatusForm')
    },
    {
        path: '/invoice_status/:id/edit',
        name: 'invoice_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('InvoiceStatusForm')
    },
    {
        path: '/deal_status',
        name: 'deal_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStatuses')
    },
    {
        path: '/deal_status/business_process/:businessProcessId/create',
        name: 'deal_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStatusForm')
    },
    {
        path: '/deal_status/:id/business_process/:businessProcessId/edit',
        name: 'deal_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStatusForm')
    },
    {
        path: '/task_status',
        name: 'task_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskStatuses')
    },
    {
        path: '/task_status/create',
        name: 'task_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskStatusForm')
    },
    {
        path: '/task_status/:id/edit',
        name: 'task_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskStatusForm')
    },
    {
        path: '/task_score',
        name: 'task_score',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskScores')
    },
    {
        path: '/task_score/create',
        name: 'task_score.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskScoreForm')
    },
    {
        path: '/task_score/:id/edit',
        name: 'task_score.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskScoreForm')
    },
    {
        path: '/funnel_status',
        name: 'funnel_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('FunnelStatuses')
    },
    {
        path: '/funnel_status/create',
        name: 'funnel_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('FunnelStatusForm')
    },
    {
        path: '/funnel_status/:id/edit',
        name: 'funnel_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('FunnelStatusForm')
    },
    {
        path: '/document_type',
        name: 'document_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentTypes')
    },
    {
        path: '/document_type/create',
        name: 'document_type.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentTypeForm')
    },
    {
        path: '/document_type/:id/edit',
        name: 'document_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentTypeForm')
    },
    {
        path: '/document_flow_type',
        name: 'document_flow_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentFlowTypes')
    },
    {
        path: '/document_flow_type/create',
        name: 'document_flow_type.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentFlowTypeForm')
    },
    {
        path: '/document_flow_type/:id/edit',
        name: 'document_flow_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentFlowTypeForm')
    },
    {
        path: '/document_flow_status',
        name: 'document_flow_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentFlowStatuses')
    },
    {
        path: '/document_flow_status/create',
        name: 'document_flow_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentFlowStatusForm')
    },
    {
        path: '/document_flow_status/:id/edit',
        name: 'document_flow_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentFlowStatusForm')
    },
    {
        path: '/document',
        name: 'document',
        meta: {layout: defaultLayout, auth: {roles: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Documents')
    },
    {
        path: '/document/create',
        name: 'document.create',
        meta: {layout: defaultLayout, auth: {roles: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DocumentForm')
    },
    {
        path: '/document/:id/show',
        name: 'document.show',
        meta: {layout: defaultLayout, auth: {roles: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('DocumentShow'),
        props: true
    },

    {
        path: '/basket',
        name: 'basket',
        meta: {layout: defaultLayout, auth: {roles: ['documents','administrator', 'director', 'accountant', 'manager', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Baskets')
    },
    {
        path: '/type_form',
        name: 'type_form',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeForms')
    },
    {
        path: '/type_form/create',
        name: 'type_form.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeFormForm')
    },
    {
        path: '/type_form/:id/edit',
        name: 'type_form.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeFormForm')
    },
    {
        path: '/tab',
        name: 'tab',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Tabs')
    },
    {
        path: '/tab/create',
        name: 'tab.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TabForm')
    },
    {
        path: '/tab/:id/edit',
        name: 'tab.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TabForm')
    },
    {
        path: '/group',
        name: 'group',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Groups')
    },
    {
        path: '/group/create',
        name: 'group.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('GroupForm')
    },
    {
        path: '/group/:id/edit',
        name: 'group.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('GroupForm')
    },
    {
        path: '/required_field',
        name: 'required_field',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('RequiredFields')
    },
    {
        path: '/required_field/create',
        name: 'required_field.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('RequiredFieldForm')
    },
    {
        path: '/required_field/:id/edit',
        name: 'required_field.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('RequiredFieldForm')
    },

    {
        path: '/type_vacation',
        name: 'type_vacation',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('TypeVacations')
    },
    {
        path: '/type_vacation/create',
        name: 'type_vacation.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('TypeVacationForm')
    },
    {
        path: '/type_vacation/:id/edit',
        name: 'type_vacation.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('TypeVacationForm')
    },

    {
        path: '/urgency_departure',
        name: 'urgency_departure',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('UrgencyDepartures')
    },
    {
        path: '/urgency_departure/create',
        name: 'urgency_departure.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('UrgencyDepartureForm')
    },
    {
        path: '/urgency_departure/:id/edit',
        name: 'urgency_departure.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('UrgencyDepartureForm')
    },
    {
        path: '/task_type',
        name: 'task_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskTypes')
    },
    {
        path: '/task_type/create',
        name: 'task_type.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskTypeForm')
    },
    {
        path: '/task_type/:id/edit',
        name: 'task_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TaskTypeForm')
    },

    {
        path: '/case_type',
        name: 'case_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CaseTypes')
    },
    {
        path: '/case_type/create',
        name: 'case_type.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CaseTypeForm')
    },
    {
        path: '/case_type/:id/edit',
        name: 'case_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CaseTypeForm')
    },

    {
        path: '/case/:id/show',
        name: 'case.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('CaseShow')
    },

    {
        path: '/location_event',
        name: 'location_event',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('LocationEvents')
    },
    {
        path: '/location_event/create',
        name: 'location_event.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('LocationEventForm')
    },
    {
        path: '/location_event/:id/edit',
        name: 'location_event.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('LocationEventForm')
    },
    {
        path: '/case_status',
        name: 'case_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CaseStatuses')
    },
    {
        path: '/case_status/create',
        name: 'case_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CaseStatusForm')
    },
    {
        path: '/case_status/:id/edit',
        name: 'case_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CaseStatusForm')
    },

    {
        path: '/sex',
        name: 'sex',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Sexes')
    },
    {
        path: '/sex/create',
        name: 'sex.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SexForm')
    },
    {
        path: '/sex/:id/edit',
        name: 'sex.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SexForm')
    },
    {
        path: '/type_activity',
        name: 'type_activity',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeActivities')
    },
    {
        path: '/type_activity/create',
        name: 'type_activity.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeActivityForm')
    },
    {
        path: '/type_activity/:id/edit',
        name: 'type_activity.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeActivityForm')
    },
    {
        path: '/attraction_channel',
        name: 'attraction_channel',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('AttractionChannels')
    },
    {
        path: '/attraction_channel/create',
        name: 'attraction_channel.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('AttractionChannelForm')
    },
    {
        path: '/attraction_channel/:id/edit',
        name: 'attraction_channel.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('AttractionChannelForm')
    },

    {
        path: '/form_property',
        name: 'form_property',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('FormProperties')
    },
    {
        path: '/form_property/create',
        name: 'form_property.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('FormPropertyForm')
    },
    {
        path: '/form_property/:id/edit',
        name: 'form_property.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('FormPropertyForm')
    },
    {
        path: '/invite',
        name: 'invite',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('Invites')
    },
    {
        path: '/storage',
        name: 'storage',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Storages')
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Notifications')
    },

    {
        path: '/messages',
        name: 'messages',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'accountant', 'general_manager', 'guide', 'hr_manager', 'lawyer']}},
        component: loadView('Messages'),
        props: true,
    },

    {
        path: '/station',
        name: 'station',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('Stations')
    },
    {
        path: '/station/create',
        name: 'station.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('StationForm')
    },
    {
        path: '/station/:id/edit',
        name: 'station.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'manager', 'general_manager', 'guide']}},
        component: loadView('StationForm')
    },
    {
        path: '/status',
        name: 'status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Statuses')
    },
    {
        path: '/status/create',
        name: 'status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('StatusForm')
    },
    {
        path: '/status/:id/edit',
        name: 'status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('StatusForm')
    },
    {
        path: '/sub_status',
        name: 'sub_status',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SubStatuses')
    },
    {
        path: '/sub_status/create',
        name: 'sub_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SubStatusForm')
    },
    {
        path: '/sub_status/:id/edit',
        name: 'sub_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SubStatusForm')
    },
    {
        path: '/loading_type',
        name: 'loading_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('LoadingTypes')
    },
    {
        path: '/loading_type/create',
        name: 'loading_type.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('LoadingTypeForm')
    },
    {
        path: '/loading_type/:id/edit',
        name: 'loading_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('LoadingTypeForm')
    },

    {
        path: '/country',
        name: 'country',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Countries')
    },
    {
        path: '/country/create',
        name: 'country.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CountryForm')
    },
    {
        path: '/country/:id/edit',
        name: 'country.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CountryForm')
    },
    {
        path: '/supplier',
        name: 'supplier',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Suppliers')
    },
    {
        path: '/supplier/create',
        name: 'supplier.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SupplierForm')
    },
    {
        path: '/supplier/:id/edit',
        name: 'supplier.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('SupplierForm')
    },
    {
        path: '/type_export',
        name: 'type_export',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeExports')
    },
    {
        path: '/type_export/create',
        name: 'type_export.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeExportForm')
    },
    {
        path: '/type_export/:id/edit',
        name: 'type_export.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeExportForm')
    },

    {
        path: '/payer',
        name: 'payer',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Payers')
    },
    {
        path: '/payer/create',
        name: 'payer.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('PayerForm')
    },
    {
        path: '/payer/:id/edit',
        name: 'payer.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('PayerForm')
    },
    {
        path: '/type_transport',
        name: 'type_transport',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeTransports')
    },
    {
        path: '/type_transport/create',
        name: 'type_transport.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeTransportForm')
    },
    {
        path: '/type_transport/:id/edit',
        name: 'type_transport.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeTransportForm')
    },


    {
        path: '/type_client',
        name: 'type_client',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeClients')
    },
    {
        path: '/type_client/create',
        name: 'type_client.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeClientForm')
    },
    {
        path: '/type_client/:id/edit',
        name: 'type_client.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeClientForm')
    },

    {
        path: '/base',
        name: 'base',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Bases')
    },
    {
        path: '/base/create',
        name: 'base.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('BaseForm')
    },
    {
        path: '/base/:id/edit',
        name: 'base.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('BaseForm')
    },

    {
        path: '/type_container',
        name: 'type_container',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeContainers')
    },
    {
        path: '/type_container/create',
        name: 'type_container.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeContainerForm')
    },
    {
        path: '/type_container/:id/edit',
        name: 'type_container.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeContainerForm')
    },
    {
        path: '/currency',
        name: 'currency',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('Currencies')
    },
    {
        path: '/currency/create',
        name: 'currency.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CurrencyForm')
    },
    {
        path: '/currency/:id/edit',
        name: 'currency.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('CurrencyForm')
    },
    {
        path: '/deal_type',
        name: 'deal_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealTypes')
    },
    {
        path: '/deal_type/create',
        name: 'deal_type.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealTypeForm')
    },
    {
        path: '/deal_type/:id/edit',
        name: 'deal_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealTypeForm')
    },

    {
        path: '/type_expense',
        name: 'type_expense',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeExpenses')
    },
    {
        path: '/type_expense/create',
        name: 'type_expense.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeExpenseForm')
    },
    {
        path: '/type_expense/:id/edit',
        name: 'type_expense.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('TypeExpenseForm')
    },
    {
        path: '/deal_step',
        name: 'deal_step',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStepProcesses')
    },
    {
        path: '/deal_step/:business_process/show',
        name: 'deal_step_show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealSteps')
    },
    {
        path: '/deal_step/:business_process/:type',
        name: 'deal_step_participants',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStepSettings')
    },
    {
        path: '/deal_step/:business_process/:type',
        name: 'deal_step_auditors',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStepSettings')
    },
    {
        path: '/deal_step/:business_process/:type',
        name: 'deal_step_moderators',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStepSettings')
    },
    {
        path: '/deal_step/business_process/:business_process/create',
        name: 'deal_step.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStepForm')
    },
    {
        path: '/deal_step/:id/business_process/:business_process/edit',
        name: 'deal_step.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStepForm')
    },
    {
        path: '/business_process',
        name: 'business_process',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('BusinessProcesses')
    },
    {
        path: '/business_process/create',
        name: 'business_process.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('BusinessProcessForm')
    },
    {
        path: '/business_process/:id/edit',
        name: 'business_process.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('BusinessProcessForm')
    },

    {
        path: '/business_process/:businessProcessId/steps',
        name: 'business_process.steps',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealSteps'),
        props: true
    },

    {
        path: '/business_process/:businessProcessId/statuses',
        name: 'business_process.statuses',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealStatuses'),
        props: true
    },
    {
        path: '/business_process/:businessProcessId/main_statuses',
        name: 'business_process.main_statuses',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealMainStatuses'),
        props: true
    },
    {
        path: '/deal_main_status/create',
        name: 'deal_main_status.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealMainStatusForm')
    },
    {
        path: '/deal_main_status/:id/edit',
        name: 'deal_main_status.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DealMainStatusForm')
    },

    {
        path: '/business_process/:businessProcessId/documents',
        name: 'business_process.documents',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('DocumentTypes'),
        props: true
    },

    {
       path: '/page',
       name: 'page',
       meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
       component: loadView('Pages')
   },
   {
       path: '/page/create',
       name: 'page.create',
       meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
       component: loadView('PageForm')
   },
   {
       path: '/page/:id/edit',
       name: 'page.edit',
       meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
       component: loadView('PageForm')
   },
    {
       path: '/plan',
       name: 'plan',
       meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
       component: loadView('Plans')
   },
   {
       path: '/plan/create',
       name: 'plan.create',
       meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
       component: loadView('PlanForm')
   },
   {
       path: '/plan/:id/edit',
       name: 'plan.edit',
       meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
       component: loadView('PlanForm')
   },
    {
        path: '/about',
        name: 'about',
        meta: {layout: defaultLayout, auth: { roles: ['administrator','director'] }},
        component: loadView('About')
    },
    {
        path: '/report/call',
        name: 'report_call',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('reports/Call')
    },
    {
        path: '/report/task',
        name: 'report_task',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('reports/Task')
    },
    {
        path: '/report/deal',
        name: 'report_deal',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('reports/Deal')
    },
    {
        path: '/report/application',
        name: 'report_application',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('reports/Application')
    },
    {
        path: '/report/order',
        name: 'report_order',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('reports/Order')
    },
    {
        path: '/activity_log',
        name: 'activity_log',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('ActivityLogs')
    },
    {
        path: '/activity_log/:id/show',
        name: 'activity_log.show',
        meta: {layout: defaultLayout, auth: {roles: ['administrator','director']}},
        component: loadView('ActivityLogForm')
    },
    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'single-page', auth: false },
        component: loadView('Auth/Login')
    },

    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Login')
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/Forgot')
    },
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'single-page', auth: false},
        component: loadView('Auth/ResetPassword')
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: defaultLayout, auth: true},
        component: loadView('Errors/NotFound')
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: defaultLayout, auth: true},
        component: loadView('Errors/Forbidden')
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Blocked')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        meta: {layout: 'single-page', auth: undefined},
        component: loadView('Errors/Maintenance')
    },
    {
        name: 'department',
        path: '/department',
        meta: {
            layout: defaultLayout,
            auth: {roles: ['administrator', 'director', 'hr_manager']},
            query: {
                asd: 'asd'
            }
        },
        component: loadView('Departments')
    },
    {
        path: '/department/tree',
        name: 'department.tree',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('DepartmentsTree')
    },
    {
        path: '/department/:id',
        name: 'department.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('DepartmentForm')
    },
    {
        path: '/department/create',
        name: 'department.create',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director', 'hr_manager']}},
        component: loadView('DepartmentForm')
    },
    {
        path: '/eav',
        name: 'eav.entity_type',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director']}},
        component: loadView('EavEntities')
    },
    // {
    //     path: '/eav/form',
    //     name: 'eav.entity_type.create',
    //     meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director',]}},
    //     component: loadView('EavEntityForm')
    // },
    {
        path: '/eav/:slug',
        name: 'eav.entity_type.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director',]}},
        component: loadView('EavEntityForm'),
        props: true,
    },
    {
        path: '/eav/:slug/property',
        name: 'eav.entity_type.property',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director',]}},
        component: loadView('EavProperties'),
        props: true
    },
    {
        path: '/eav/:entity_type_slug/property/:id',
        name: 'eav.entity_type.property.edit',
        meta: {layout: defaultLayout, auth: {roles: ['administrator', 'director',]}},
        component: loadView('EavPropertyForm'),
        props: true
    },
    {
        path: '*',
        redirect: '/404',
        meta: {layout: 'default', auth: undefined},
    },

    /* acl */
    {
        path: '/permissions',
        name: 'permissions',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'administrator'
                ]
            }
        },
        component: loadView('Permissions'),
    },

    /** selling */
    {
        name: 'sale',
        path: '/sale',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/Main"),
    },

    {
        path: '/sale/buyer_order',
        name: 'sale.buyer_order',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/BuyerOrder"),
    },

    {
        path: '/sale/buyer_order/:uuid',
        name: 'sale.buyer_order.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/BuyerOrderEdit"),
        props: true
    },

    {
        path: '/sale/invoice_outgoing',
        name: 'sale.invoice_outgoing',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/BuyerOrder"),
    },

    {
        path: '/sale/realization',
        name: 'realization',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/Realization/List"),
    },

    {
        path: '/sale/realization/:uuid',
        name: 'realization.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/Realization/Form"),
        props: true
    },

    {
        path: '/sale/buyer_return',
        name: 'sale.buyer_return',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/BuyerReturn"),
    },

    {
        path: '/sale/report',
        name: 'sale.report',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Sale/Report"),
    },

    /** buying */
    {
        name: 'buy',
        path: '/buy',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/Main"),
    },

    {
        path: '/buy/order_to_buy',
        name: 'order_to_buy',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/OrderToBuy"),
    },

    {
        path: '/buy/order_to_buy/:uuid',
        name: 'order_to_buy.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/OrderToBuyEdit"),
        props: true
    },

    {
        path: '/buy/incoming',
        name: 'incoming',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/Incoming/List"),
    },

    {
        path: '/buy/incoming/:uuid',
        name: 'incoming.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/Incoming/Form"),
        props: true
    },

    {
        path: '/buy/incoming_returning',
        name: 'incoming_returning',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/IncomingReturning/List"),
    },

    {
        path: '/buy/incoming_returning/:uuid',
        name: 'incoming_returning.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/IncomingReturning/Form"),
        props: true
    },

    {
        path: '/buy/invoice',
        name: 'accounting_invoice',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/Invoice"),
    },

    {
        path: '/buy/invoice/:uuid',
        name: 'accounting_invoice.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/InvoiceEdit"),
        props: true
    },

    // {
    //     path: '/buy/buying',
    //     name: 'buying',
    //     meta: {
    //         layout: defaultLayout,
    //         auth: {
    //             roles: [
    //                 'warehouse_accounting'
    //             ]
    //         }
    //     },
    //     component: loadView("Buy/Buying"),
    // },
    //
    // {
    //     path: '/buy/buying/:uuid',
    //     name: 'buying.edit',
    //     meta: {
    //         layout: defaultLayout,
    //         auth: {
    //             roles: [
    //                 'warehouse_accounting'
    //             ]
    //         }
    //     },
    //     component: loadView("Buy/BuyingEdit"),
    //     props: true
    // },

    {
        path: '/buy/report',
        name: 'buy.report',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Buy/Report"),
    },

    /** Склад **/
    {
        path: '/warehouse',
        name: 'warehouse_main',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/Main'),
    },

    {
        path: '/warehouse/warehouse',
        name: 'warehouse',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/Warehouses'),
    },

    {
        path: '/warehouse/warehouse/:uuid',
        name: 'warehouse.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseForm'),
        props: true
    },

    {
        path: '/warehouse/zone',
        name: 'warehouse_zone',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseZone'),
    },

    {
        path: '/warehouse/shelf',
        name: 'warehouse_shelf',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseShelf'),
    },

    {
        path: '/warehouse/shelf/:uuid',
        name: 'warehouse_shelf.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseShelfForm'),
        props: true
    },

    {
        path: '/warehouse/zone/:uuid',
        name: 'warehouse_zone.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseZoneForm'),
        props: true
    },

    {
        path: '/warehouse/cell',
        name: 'warehouse_cell',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseCell'),
    },

    {
        path: '/warehouse/cell/:uuid',
        name: 'warehouse_cell.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/WarehouseCellForm'),
        props: true
    },

    {
        path: '/warehouse/nomenclature_types',
        name: 'nomenclature_types',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/NomenclatureTypes'),
    },

    {
        path: '/warehouse/dashboard',
        name: 'warehouse.dashboard',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Warehouse/Dashboard'),
    },

    {
        path: '/warehouse/stock',
        name: 'warehouse.stock',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView("Warehouse/Stock"),
    },

    /* accounting */
    {
        path: '/accounting',
        name: 'accounting',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Main'),
    },

    {
        path: '/accounting/transaction_log',
        name: 'transaction_log',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/TransactionLog'),
    },

    {
        path: '/accounting/chart_of_account',
        name: 'chart_of_account',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/ChartOfAccount'),
    },

    {
        path: '/accounting/payment',
        name: 'account_payment',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Payment'),
    },

    {
        path: '/accounting/payment/:uuid',
        name: 'account_payment.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/PaymentForm'),
        props: true
    },

    {
        path: '/accounting/dashboard',
        name: 'accounting.dashboard',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Dashboard'),
    },

    {
        path: '/accounting/report/cashflow',
        name: 'accounting.report.cashflow',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Report/CashFlow'),
    },

    {
        path: '/accounting/report/income_expense',
        name: 'accounting.report.income_expense',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Report/IncomeExpense'),
    },

    {
        path: '/accounting/report/movement',
        name: 'accounting.report.movement',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Report/Movement'),
    },

    {
        path: '/accounting/report/trail_balance',
        name: 'accounting.report.trail_balance',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'warehouse_accounting'
                ]
            }
        },
        component: loadView('Accounting/Report/TrailBalance'),
    },

    /* price_type */
    {
        path: '/warehouse/price_type',
        name: 'price_types',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'price_types'
                ]
            }
        },
        component: loadView('Warehouse/PriceTypes'),
    },
    {
        path: '/warehouse/price_type/:uuid',
        name: 'price_type.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'price_types'
                ]
            }
        },
        component: loadView('Warehouse/PriceTypeForm'),
        props: true
    },

    /* product_types */
    {
        path: '/warehouse/nomenclature_type',
        name: 'nomenclature_types',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'nomenclature_accounting'
                ]
            }
        },
        component: loadView('Warehouse/ProductTypes'),
    },
    {
        path: '/warehouse/nomenclature_type/:uuid',
        name: 'nomenclature_type.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'nomenclature_accounting'
                ]
            }
        },
        component: loadView('Warehouse/NomenclatureTypeForm'),
        props: true
    },

    /* product_group */
    {
        path: '/warehouse/nomenclature_group/:uuid',
        name: 'nomenclature_group.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'nomenclature_accounting'
                ]
            }
        },
        component: loadView('Warehouse/NomenclatureGroupForm'),
        props: true
    },

    /* products */
    {
        path: '/warehouse/nomenclature',
        name: 'nomenclature',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'nomenclature_accounting'
                ]
            }
        },
        component: loadView('Warehouse/Nomenclature'),
    },
    {
        path: '/warehouse/nomenclature/:uuid',
        name: 'nomenclature.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'nomenclature_accounting'
                ]
            }
        },
        component: loadView('Warehouse/NomenclatureEdit'),
        props: true
    },

    /* units */
    {
        path: '/warehouse/units',
        name: 'units',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'units'
                ]
            }
        },
        component: loadView('Warehouse/Units'),
    },
    {
        path: '/warehouse/units/:uuid',
        name: 'unit.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'units'
                ]
            }
        },
        component: loadView('Warehouse/UnitForm'),
        props: true
    },

    {
        path: '/organization',
        name: 'organization',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: ['administrator', 'director']
            }
        },
        component: loadView('Organization'),
        props: true
    },
    {
        path: '/organization/:uuid',
        name: 'organization.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: ['administrator', 'director']
            }
        },
        component: loadView('OrganizationForm'),
        props: true
    },
    {
        path: '/organization/:uuid/account',
        name: 'organization_account',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: ['administrator', 'director']
            }
        },
        component: loadView('OrganizationAccount'),
        props: true
    },
    {
        path: '/organization/:uuid/account/:accountUuid',
        name: 'organization_account.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: ['administrator', 'director']
            }
        },
        component: loadView('OrganizationAccountForm'),
        props: true
    },

    {
        path: '/vat',
        name: 'vat',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'vats'
                ]
            }
        },
        component: loadView('Vat'),
        props: true
    },
    {
        path: '/vat/:uuid',
        name: 'vat.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'vats'
                ]
            }
        },
        component: loadView('VatForm'),
        props: true
    },

    {
        path: '/bank',
        name: 'bank',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'vats'
                ]
            }
        },
        component: loadView('Bank'),
        props: true
    },
    {
        path: '/bank/:uuid',
        name: 'bank.edit',
        meta: {
            layout: defaultLayout,
            auth: {
                roles: [
                    'vats'
                ]
            }
        },
        component: loadView('BankForm'),
        props: true
    },
];

Vue.router = new VueRouter({
    parseQuery: qs.parse,
    stringifyQuery(query) {
        let result = qs.stringify(query, {encode: false});
        return result ? ('?' + result) : '';
    },
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    /*
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return window.goTo(scrollTo)
    },
    */
    /*
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    */
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes: routes
});

export default Vue.router;
